import React from 'react';
import Card from '../components/card/Card'
import projects from '../components/data/ProjectsData'

function ProjectsAll() {
  return (
    <div className="wrapper">
    <div className="all-projects-page">
        <div className="section-header">
      <h1>Projects</h1>
      <p>These are some of the projects I’ve worked on throughout my journey in Front End Development and UI Design. From <em>logo design</em> and <em>style guides </em> to <em>UI/UX design and fully functional websites</em> — including this portfolio — these projects showcase my skills in both design and development."</p>
      </div>
      <div className="all-projects card-container">
        {projects.map((project) => (
          <Card
            key={project.id}
            title={project.title}
            image={project.image}
            link={`/projects/${project.id}`} // Link to the Project Detail page
            category={project.category}
          />
        ))}
      </div>
    </div>
    </div>
  );
}

export default ProjectsAll;