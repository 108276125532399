import greeceI from '../../assets/images/photography/zakynthos/greece_grotto.jpg';
import greeceII from '../../assets/images/photography/zakynthos/greece_beach.jpg';
import greeceIII from '../../assets/images/photography/zakynthos/greece_house.jpg';
import greeceIV from '../../assets/images/photography/zakynthos/greece_nobelos.jpg';
import greeceV from '../../assets/images/photography/zakynthos/greece_oceanII.jpg';
import greeceVI from '../../assets/images/photography/zakynthos/greece_hotel.jpg';
import greeceVII from '../../assets/images/photography/zakynthos/greece_outside.jpg';
import greeceVIII from '../../assets/images/photography/zakynthos/greece_bar.jpg';
import greeceIX from '../../assets/images/photography/zakynthos/greece_rocks.jpg';
import greeceX from '../../assets/images/photography/zakynthos/greece_ocean.jpg';

import romeI from '../../assets/images/photography/rome/roma_bridge.jpg';
import romeII from '../../assets/images/photography/rome/roma_building.jpg';
import romeIII from '../../assets/images/photography/rome/roma_cafe.jpg';
import romeIV from '../../assets/images/photography/rome/roma_church.jpg';
import romeV from '../../assets/images/photography/rome/roma_icecreamtruck.jpg';
import romeVI from '../../assets/images/photography/rome/roma_river.jpg';
import romeVII from '../../assets/images/photography/rome/roma_trevi.jpg';

import chileI from '../../assets/images/photography/chile/chile_rocks.jpg';
import chileII from '../../assets/images/photography/chile/DSC02147.jpg';
import chileIII from '../../assets/images/photography/chile/buenos_aires_tree.jpg';
import chileIV from '../../assets/images/photography/chile/chile_sealion.jpg';
import chileV from '../../assets/images/photography/chile/chile_santiago.jpg';
import chileVI from '../../assets/images/photography/chile/chile_rocks_valparaiso.jpg';
import chileVII from '../../assets/images/photography/chile/chile_sealionII.jpg';
import chileVIII from '../../assets/images/photography/chile/argentina_ricoleta.jpg';
import chileIX from '../../assets/images/photography/chile/argentina_ricoletaII.jpg';
import chileX from '../../assets/images/photography/chile/DSC03083.jpg';

import designI from '../../assets/images/photography/design/kitchen_fagerborg.jpg';
import designII from '../../assets/images/photography/design/toteme_vase.jpg';
import designIII from '../../assets/images/photography/design/lesante_hotel.jpg';
import designIV from '../../assets/images/photography/design/sofa_fagerborg.jpg';









export const zakynthosImages = [
  { id: 1, src: greeceI, alt: '' },
  { id: 2, src: greeceII, alt: '' },
  { id: 3, src: greeceIII, alt: '' },
  { id: 4, src: greeceIV, alt: '' },
  { id: 5, src: greeceV, alt: '' },
  { id: 6, src: greeceVI, alt: '' },
  { id: 7, src: greeceVII, alt: '' },
  { id: 8, src: greeceVIII, alt: '' },
  { id: 9, src: greeceIX, alt: '' },
  { id: 10, src: greeceX, alt: '' },

]


export const romeImages = [
  { id: 1, src: romeI, alt: '' },
  { id: 2, src: romeII, alt: '' },
  { id: 3, src: romeIII, alt: '' },
  { id: 4, src: romeIV, alt: '' },
  { id: 5, src: romeV, alt: '' },
  { id: 6, src: romeVI, alt: '' },
  { id: 7, src: romeVII, alt: '' },


]

export const chileImages = [
  { id: 1, src: chileI, alt: '' },
  { id: 2, src: chileII, alt: '' },
  { id: 3, src: chileIII, alt: '' },
  { id: 4, src: chileIV, alt: '' },
  { id: 5, src: chileV, alt: '' },
  { id: 6, src: chileVI, alt: '' },
  { id: 7, src: chileVII, alt: '' },
  { id: 8, src: chileVIII, alt: '' },
  { id: 9, src: chileIX, alt: '' },
  { id: 10, src: chileX, alt: '' },


]

export const designImages = [
    { id: 1, src: designI, alt: '' },
    { id: 2, src: designII, alt: '' },
    { id: 3, src: designIII, alt: '' },
    { id: 4, src: designIV, alt: '' },

  
  ]