import React from 'react'
import { ReactComponent as LogoSVG } from '../assets/logos/sara_otterdal_logo.svg';

const LogoNav = () => {
  return (
    <a href="/">
        <LogoSVG id="sara-logo-nav" />
       </a>
  )
}

export default LogoNav