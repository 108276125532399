import React, { useState } from 'react';

function PhotoGallery({ images }) { // Accepts images as a prop
  const [selectedImageIndex, setSelectedImageIndex] = useState(null); // Track index of clicked image

  const openLightbox = (index) => {
    setSelectedImageIndex(index);
  };

  const closeLightbox = () => {
    setSelectedImageIndex(null);
  };

  const goToNext = (e) => {
    e.stopPropagation(); 
    setSelectedImageIndex((selectedImageIndex + 1) % images.length);
  };

  const goToPrevious = (e) => {
    e.stopPropagation();
    setSelectedImageIndex(
      (selectedImageIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div>
      {/* Gallery Grid */}
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div key={image.id} className="gallery-item" onClick={() => openLightbox(index)}>
            <img src={image.src} alt={image.alt} className="gallery-img" />
          </div>
        ))}
      </div>

      {/* Lightbox (Modal) */}
      {selectedImageIndex !== null && (
        <div className="lightbox" onClick={closeLightbox}>
          <span className="close">&times;</span>
          <div className="prev-button" onClick={goToPrevious}>
          <span className="material-symbols-outlined">chevron_left</span>
          </div>

          <img
            src={images[selectedImageIndex].src}
            alt={images[selectedImageIndex].alt}
            className="lightbox-img"
          />

<div className="next-button" onClick={goToNext}>
          <span className="material-symbols-outlined">chevron_right</span>
        </div>
        </div>
      )}
    </div>
  );
}

export default PhotoGallery;