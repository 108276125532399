import React from 'react'
import { Instagram, FacebookOutlined, Pinterest} from '@mui/icons-material';

function Footer() {
  return (
    <footer className="container">
      <div className="contact">
        <ul>
          <li><strong>Questions?</strong></li>
          <li>hello@saraotterdal.com</li>
        </ul>
      </div>
      <ul className="footer-icons">
        <li><Instagram/></li>
        <li><FacebookOutlined/></li>
        <li><Pinterest/></li>
        </ul>
    </footer>
  )
}

export default Footer;