import React from 'react'
import profileImage from '../assets/images/chile_sara.jpg'
import IconsNav from '../components/IconsNav'
import PhotoGallery from '../components/PhotoGallery'
import {zakynthosImages, romeImages, chileImages, designImages} from '../components/data/GalleryData'

function About() {
  return (
    <div className="wrapper">
      <IconsNav/>
    <div className="about-page">
      {/* About Section */}
      <section className="about-section">
      <div className="about-container">
        {/* Left Side - Text */}
        <div className="about-text">
          <h3>About me</h3>
          <h1>Sara Otterdal</h1>
          <p>
            I am a front end developer and digital designer, based in Oslo, Norway.
          </p>
        </div>
        <div className="about-image">
    <img src={profileImage} alt="Sara Otterdal" />
  </div>
      </div>
    </section>

         <section className="section-header">
         <h2>Education & skills</h2>
         </section>
         <section className="education-skills-section">
        <div className="about-education">
        <div className="education-cards">
          <div className="education-card">
            <p>2020-2022</p>
            <h3>Front-End Development</h3>
            <p>Noroff  School of Technology</p>
          </div>
          <div className="education-card">
            <p>2023-2024</p>
            <h3>UI Design</h3>
            <p>Noroff School of Technology</p>
          </div>
        </div>
        </div>
      <div className="about-skills">
      <section className="about">
        <div className="skill">
          <p>UI Design</p>
          <div className="skill-bar">
            <div className="skill-level" style={{ width: '85%' }}></div>
          </div>
        </div>
        <div className="skill">
          <p>Front-End Development</p>
          <div className="skill-bar">
            <div className="skill-level" style={{ width: '70%' }}></div>
          </div>
        </div>
        <div className="skill">
          <p>Interior Design</p>
          <div className="skill-bar">
            <div className="skill-level" style={{ width: '75%' }}></div>
          </div>
          </div>
          <div className="skill">
          <p>Adobe XD</p>
          <div className="skill-bar">
            <div className="skill-level" style={{ width: '95%' }}></div>
          </div>
          </div>
      </section>
      </div>
      </section>
      <section className="about-interests">
        <h2>Interests</h2>
        <p>
        Outside of work, I enjoy exploring Oslo’s creative scene and spending time in nature. I’m drawn to minimalistic, monochrome aesthetics, both in design and in my personal life, where I find inspiration for my creative work.
        </p>
      </section>
      <section className="about-gallery">
        <h2>Zakynthos, Greece</h2>
        <p>May 2024</p>
      <PhotoGallery images={zakynthosImages} />
        </section>
        <section className="about-gallery">
          <h2>Rome, Italy</h2>
          <p>September 2023</p>
      <PhotoGallery images={romeImages} />
        </section>
        <section className="about-gallery">
        <h2>Chile & Argentina</h2>
        <p>January 2018</p>
      <PhotoGallery images={chileImages} />
        </section>
        <section className="about-gallery">
        <h2>Things I like</h2>
        <p>Interior design and other</p>
      <PhotoGallery images={designImages} />
        </section>
      </div>
      
     
    </div>
    
  )
}

export default About