import solarsmart_desktop from '../../assets/images/imac_solarsmart_logo.jpg';
import mockup_homdecor from '../../assets/images/mockup_homedecor.jpg';
import mockup_holidaze from '../../assets/images/holidaze_iphone.jpg';
import mockup_holidaze_imac from '../../assets/images/holidaze_mockup_imac.png';
import safe_paw_rescue from '../../assets/images/safe_paw_macbook.jpg';
import mockup_nwt from '../../assets/images/nwt_android.jpg';
import mockup_nor_mobler from '../../assets/images/mockup_nor_mobler.jpg';
import safe_paw_IV from '../../assets/images/safe_paw_IV.png';
import safe_paw_II from '../../assets/images/Safe_paw_II.png';
import safe_paw_III from '../../assets/images/Safe_paw_III.png';
import solarsmart_logo from '../../assets/logos/solarsmart_logo.png';
import solarsmart_imac from '../../assets/images/solar_smart_imac.jpg';
import homedecor_imac from '../../assets/images/mockup_imac_homedecor.png';
import homedecor_ipad from '../../assets/images/home_decor_ipad_logo.jpg';
import homedecor_macbook from '../../assets/images/MacBook Pro 16_homedecor.png';
import holidaze_logo from '../../assets/logos/holidaze_logo.png';
import nwt_desktop from '../../assets/images/nwt_desktop.png';
import nwt_ds from '../../assets/images/nwt_ds.png';
import nor_mobler_II from '../../assets/images/nor_mobler_II.png';
import nor_mobler_III from '../../assets/images/nor_mobler_III.png';
import london_I from '../../assets/images/bad_london.jpg';
import london_II from '../../assets/images/stue_londonII.jpg';
import london_III from '../../assets/images/stue_sofa_london.jpg';
import london_IV from '../../assets/images/soverom_london.jpg'




const projectsData = [

    {
      id: 1,
      title: "Solarsmart",
      year: 2022,
      image: solarsmart_desktop, // Adjust image path if necessary
      skills: ["UI Design", "Logo Design", "Design System", "Prototyping"],
      description:
        "SolarSmart is a UI design project focused on creating a brand identity, design system, and a prototype for a solar energy company. The project involved designing a cohesive and modern logo, developing a comprehensive design system, and building an interactive prototype for the company's website.",
      learning:
        "Through this project, I gained a deeper understanding of crafting design systems that ensure consistency across a brand’s digital presence. Additionally, I honed my skills in logo design and interactive prototyping to create user-centric solutions.",
      link: '',
      category: "UI Design & Prototyping",
      galleryImages: [solarsmart_desktop, solarsmart_imac, solarsmart_logo],
  
    },
    {
      id: 2,
      title: "Holidaze",
      year: 2022,
      image: mockup_holidaze,
      skills: ["Logo Design", "Style Guide", "React", "WordPress"],
      description:
        "A fully responsive website for Holidaze, a tourism site in Bergen, that integrates WordPress as a headless CMS. The website was built using React to deliver a seamless user experience with dynamic content from WordPress.",
      learning:
        "This project enhanced my knowledge of integrating headless CMS with React. I also gained experience in optimizing site performance and handling real-time data.",
      link: 'https://github.com/yourecommerce', // Adjust this link to the real one
      category: "Headless CMS Website",
      galleryImages: [mockup_holidaze, mockup_holidaze_imac, holidaze_logo],
    },{
      id: 3,
      title: "Home Decor",
      year: 2021,
      image: homedecor_ipad,
      skills: ["Logo Design", "Style Guide", "Product Photography", "HTML & CSS"],
      description:
        "A fully responsive website for a home decor brand, built using HTML and CSS. I designed the logo, created the style guide, and incorporated background textures to align with the brand’s aesthetic.",
      learning:
        "I learned how to effectively create and apply a style guide while ensuring that the website remains fully responsive across different devices.",
      link: 'https://github.com/yourecommerce', // Adjust this link to the real one
      category: "Website Design",
      galleryImages: [homedecor_imac, mockup_homdecor, homedecor_macbook],
    },
 
    {
      id: 4,
      title: "Nor Møbler",
      year: 2023,
      image: mockup_nor_mobler,
      skills: ["UI Design", "UX Design", "Prototyping"],
      description:
        "A prototype for Nor Møbler, an interior design company. This project involved creating a landing page, a product page, and an analytics dashboard in Adobe XD. The design aligned with the existing brand’s logo, colors, and fonts.",
      learning:
        "I gained valuable experience in prototyping and creating detailed design flows that focus on user needs and business goals.",
      link: 'https://github.com/yourecommerce', // Adjust this link to the real one
      category: "UI/UX Prototyping",
      galleryImages: [nor_mobler_II, mockup_nor_mobler,nor_mobler_III],
    },
    {
      id: 5,
      title: "Safe Paw Rescue",
      year: 2023,
      image: safe_paw_rescue,
      skills: ["Logo Design", "Style Guide", "Web Prototyping", "Photography"],
      description:
        "This project involved designing a prototype website for Safe Paw Rescue, a dog rescue organization. I created the branding, logo, and a landing page, as well as a contact page and other key sections.",
      learning:
        "This project taught me how to create an emotional connection through design, while also organizing important information in a user-friendly manner.",
      link: 'https://github.com/yourecommerce', // Adjust this link to the real one
      category: "Web Prototyping",
      galleryImages: [safe_paw_IV, safe_paw_II, safe_paw_III],
    },
    {
      id: 6,
      title: "Native Woodland Trust",
      year: 2024,
      image: mockup_nwt,
      skills: ["Logo Design", "Style Guide", "UI/UX Design", "Prototyping"],
      description:
        "Designed a prototype for an Android app for the Native Woodland Trust, a project aimed at raising awareness about environmental conservation. The project included branding, a design system, and interactive app screens.",
      learning:
        "I improved my skills in mobile app design and creating user-friendly interfaces for Android platforms. I also gained experience in design systems and building cohesive UI elements.",
      link: 'https://github.com/yourecommerce', // Adjust this link to the real one
      category: "App Design",
      galleryImages: [mockup_nwt, nwt_desktop, nwt_ds],

    },
    {
      id: 7,
      title: "London Penthouse",
      year: 2019,
      image: london_IV,
      skills: ["Interior Design", "3D Design"],
      description:
        "This was a side interior design project that I worked on with a colleague from 3D Estate. Although I would approach some aspects differently now with more experience in 3D design, I'm still satisfied with the outcome. The project has gained some recognition on Pinterest, which is encouraging, especially from people I admire in the field.",
      learning:
        "Through this project, I learned valuable lessons about interior design in a collaborative environment and began to understand the potential of 3D design in presenting spaces. While I didn't handle the 3D aspect, this experience helped shape my future design approach.",
      link: '', 
      category: "Interior Design",
      galleryImages: [london_I, london_II, london_III],

    },


    // Add more projects here
  ];
  
  export default projectsData;