// src/components/NavBar.js
import React, { useState, useEffect } from 'react';

// logo

import LogoNav from '../../LogoHome';

// Importing Framer Motion components
import { motion } from 'framer-motion';

// Importing router 
import { Link } from 'react-router-dom';

function NavBar() {
    const [isMenuOpen, setMenuOpen] = useState(false);
    const [isLargeScreen, setIsLargeScreen] = useState(false);
  
    // Detect screen size
    useEffect(() => {
      const checkScreenSize = () => {
        setIsLargeScreen(window.innerWidth >= 1024);
      };
  
      // Check initial screen size
      checkScreenSize();
  
      // Add event listener to check screen size on resize
      window.addEventListener('resize', checkScreenSize);
  
      return () => window.removeEventListener('resize', checkScreenSize);
    }, []);
  
    const toggleMenu = () => {
      setMenuOpen(!isMenuOpen);
    };
  
    // Framer Motion variants for the menu slide-in effect (only for small screens)
    const menuVariants = {
      hidden: { x: '-100%' }, // Off-screen
      visible: {
        x: 0, // Slide into view
        transition: {
          type: 'spring',
          stiffness: 80,
          damping: 20,
          when: 'beforeChildren',
          staggerChildren: 0.2, // Stagger link animations
        },
      },
    };
  
    // Framer Motion variants for each link
    const linkVariants = {
      hidden: { opacity: 0, x: -20 },
      visible: { opacity: 1, x: 0, transition: { duration: 0.5 } },
    };
  
    return (
      <nav className="navbar">
        <div className="navbar-logo">
       <LogoNav/>
        </div>
  
        {/* Hamburger icon for mobile */}
        {!isLargeScreen && (
          <span className="material-symbols-outlined hamburger-menu" onClick={toggleMenu}>
            menu
          </span>
        )}
  
        {/* Animate the menu container only on small screens */}
        {!isLargeScreen ? (
          <motion.ul
            className="navbar-links"
            initial="hidden"
            animate={isMenuOpen ? 'visible' : 'hidden'}
            variants={menuVariants}
          >
            <motion.li variants={linkVariants}>
              <Link to="/about">About</Link>
            </motion.li>
            <motion.li variants={linkVariants}>
            <Link to="/projects">Projects</Link>
            </motion.li>
          </motion.ul>
        ) : (
          <ul className="navbar-links">
             <Link to="/about">About</Link>
             <Link to="/projects">Projects</Link>
          </ul>
        )}
      </nav>
    );
  }
  
  export default NavBar;