import React from 'react'

function Button({ text, onClick, type = 'button', className = '', icon = null}) {
    return (
      <button className={`btn ${className}`} type={type} onClick={onClick}>
        {text}   {icon && <span className="material-symbols-outlined">{icon}</span>}
      </button>
    );
  }
  
  export default Button;
