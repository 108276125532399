import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button'; // Import the existing Button component

function ViewAllProjectsButton() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/projects'); // Navigate to the "All Projects" page
  };

  return (
    <Button 
      text="View All Projects" 
      icon="arrow_forward" 
      onClick={handleClick} 
    />
  );
}

export default ViewAllProjectsButton;