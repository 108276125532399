import React from 'react'

function LogoSection({ logos }) {
    return (
      <div className="logo-section">
        <section className="header-section">
        </section>
        <div className="logo-grid">
          {logos.map((logo, index) => (
            <div key={index} className="logo-card">
              <img src={logo.src} alt={logo.alt} className="logo-image" />
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default LogoSection;