import React from 'react'
import projects from '../components/data/ProjectsData';
import Card from '../components/card/Card';
import LogoSection from '../components/LogoSection';
import { ReactComponent as Logo } from '../assets/logos/sara_o_logo.svg';
import IconsNav from '../components/IconsNav';

import logo1 from '../assets/logos/solarsmart_logo.png';
import logo2 from '../assets/logos/sara_otterdal_logo.png';
import logo3 from '../assets/logos/holidaze_logo.png';
import logo4 from '../assets/logos/nwt_logo.png';
import logo5 from '../assets/logos/pizza_slice_color.png';
import logo6 from '../assets/logos/safe_paw_rescue.png';
import logo7 from '../assets/logos/baristas_logo.png';
import logo8 from '../assets/logos/lachmanns_vei.png';
import logo9 from '../assets/logos/home-decor-by-sara-texture.png';
import ViewAllProjectsButton from '../components/ViewAllProjectsButton';


const logos = [
  { src: logo1, alt: 'Logo 1', title: 'SolarSmart' },
  { src: logo2, alt: 'Logo 2', title: 'Sara Otterdal' },
  { src: logo3, alt: 'Logo 3', title: 'Holidaze' },
  { src: logo4, alt: 'Logo 4', title: 'Native Woodland Trust' },
  { src: logo5, alt: 'Logo 5', title: 'Pizza Slice' },
  { src: logo6, alt: 'Logo 6', title: 'Safe Paw Rescue' },
  { src: logo7, alt: 'Logo 7', title: 'Baristas coffee' },
  { src: logo8, alt: 'Logo 8', title: 'Lachmanns vei' },
  { src: logo9, alt: 'Logo 8', title: 'Home Decor by Sara' },

  // Add more logos
];



function Home() {



    const featuredProjects = projects.slice(0, 6);

  return (
    <div className="wrapper">
      <IconsNav/>
      <section className="hero-section">
        <div className="hero-overlay">
          <Logo id="sara-logo"/>
        </div>
      </section>
<section className="section-header">
</section>
      <div className="featured-projects card-container">
        {featuredProjects.map((project) => (
          <Card
            key={project.id}
            year={project.category}
            title={project.title}
            image={project.image}
            link={`/projects/${project.id}
            `} // Link to the Project Detail page
          />
        ))}
      </div>
      <div className="view-all-projects-section">
    <ViewAllProjectsButton/>
  </div>
<LogoSection logos={logos}/>
     </div>
  );
}

export default Home