import React from 'react';
import { useParams, Link } from 'react-router-dom';
import projects from '../components/data/ProjectsData';
import ViewAllProjectsButton from '../components/ViewAllProjectsButton';

function ProjectDetail() {
  const { id } = useParams();
  const project = projects.find((p) => p.id === parseInt(id));

  if (!project) {
    return <h2>Project not found</h2>;
  }

  // Get the current project index
  const currentIndex = projects.findIndex((p) => p.id === parseInt(id));
  const previousProject = currentIndex > 0 ? projects[currentIndex - 1] : null;
  const nextProject = currentIndex < projects.length - 1 ? projects[currentIndex + 1] : null;

  return (
    <div className="wrapper">

      {/* Main image only for mobile */}
      <div className="project-main-image">
        <img src={project.image} alt={`${project.title} main image`} />
      </div>

      <div className="project-detail-container">
        <div className="project-info">
          <h1 className="project-title">{project.title}</h1>
          <section className="project-skills">
            <ul>
              {project.skills.map((skill, index) => (
                <li key={index}>{skill}</li>
              ))}
            </ul>
          </section>
          <section className="project-description">
            <p>{project.description}</p>
          </section>
          <section className="project-learning">
            <p>{project.learning}</p>
          </section>
          <section className="project-keys">
            <div className="project-subheader">Year: <p>{project.year}</p></div>
            <div className="project-subheader">Category: <p>{project.category}</p></div>
            <div className="project-subheader">Link: <p>{project.link}</p></div>
          </section>
        </div>

        {/* Gallery and navigation */}
        <div className="project-gallery-and-navigation">
          <div className="project-images">
            {project.galleryImages.map((image, index) => (
              <div key={index} className="project-image">
                <img src={image} alt={`Gallery ${index + 1}`} />
              </div>
            ))}
          </div>

          <div className="project-navigation">
            {previousProject && (
              <Link to={`/projects/${previousProject.id}`} className="prev-project">
                <span className="material-symbols-outlined">arrow_back</span>
                <p>Previous project</p>
              </Link>
            )}
            {nextProject && (
              <Link to={`/projects/${nextProject.id}`} className="next-project">
                <p>Next project</p>
                <span className="material-symbols-outlined">arrow_forward</span>
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetail;