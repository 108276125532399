import React from 'react'
import { FaLinkedin, FaInstagram, FaPinterest } from 'react-icons/fa'


function IconsNav() {
  return (
    <div className="social-icons">
        <ul>
          <li>
            <a href="https://www.linkedin.com/in/yourprofile" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
          </li>
          <li>
            <a href="https://github.com/yourprofile" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
          </li>
          <li>
            <a href="mailto:youremail@example.com">
              <FaPinterest />
            </a>
          </li>
        </ul>
      </div>
  )
}

export default IconsNav;