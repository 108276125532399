import NavBar from "./components/layout/nav/NavBar";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/ProjectsAll';
import ProjectDetail from './pages/ProjectDetail';
import About from "./pages/About";
import Footer from "./components/layout/Footer";
import ScrollToTop from "./components/ScrollToTop";

const App = () => {
  return (
       <Router>
         <ScrollToTop />
    <>
       <NavBar /> 
       <Routes>
         <Route path="/" element={<Home />} />
         <Route path="/projects" element={<Projects />} />
         <Route path="/projects/:id" element={<ProjectDetail />} />
         <Route path="/about" element={<About/>}/>
       </Routes>
       <Footer/>
       </>
     </Router>
     
  );
};


export default App;
